// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-screencasts-tsx": () => import("./../../../src/pages/screencasts.tsx" /* webpackChunkName: "component---src-pages-screencasts-tsx" */),
  "component---src-templates-documentation-tsx": () => import("./../../../src/templates/documentation.tsx" /* webpackChunkName: "component---src-templates-documentation-tsx" */),
  "component---src-templates-entity-tsx": () => import("./../../../src/templates/entity.tsx" /* webpackChunkName: "component---src-templates-entity-tsx" */),
  "component---src-templates-json-config-tsx": () => import("./../../../src/templates/json-config.tsx" /* webpackChunkName: "component---src-templates-json-config-tsx" */),
  "component---src-templates-reference-tsx": () => import("./../../../src/templates/reference.tsx" /* webpackChunkName: "component---src-templates-reference-tsx" */),
  "component---src-templates-screencast-tsx": () => import("./../../../src/templates/screencast.tsx" /* webpackChunkName: "component---src-templates-screencast-tsx" */)
}

